<template>
  <AreaContainer>
    <AreaNav 
      :links="links"
    ></AreaNav>
    <AreaContent>
      <router-view></router-view>
    </AreaContent>    
  </AreaContainer>
</template>

<script>
export default {
  name: "OrdersLayout",
  data() {
    return {
      links: [
        {
          route: "orders.Orders",
          name: this.$tk("OrdersLayout.Orders")
        },
        {
          route: "orders.Scheduled",
          name: this.$tk("OrdersLayout.ScheduledOrders")
        }
      ]
    }
  }
}
</script>
